@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;700&family=Inter:wght@100..900&display=swap");

.fix-safari-padding {
  -webkit-appearance: initial;
}

* {
  box-sizing: border-box !important;
}

@layer base {
  :root {
    --background: #ffffff;
    --foreground: #000021;

    --font-dark: #000000;
    --font-light: #ffffff;
    --card-foreground: #39293e;

    --primary: #10b981;
    --primary-hover: #059669;

    --secondary: #203b51;
    --secondary-hover: #2a4d6c;

    --error: #dc2626;

    --accent: #5bd27b;
    --accent-foreground: #39293e;

    --accepted: #e6fffb;
    --accepted-text: #089c63;

    --declined: #fff2e8;
    --declined-text: #d4380d;

    --ring: #f3f4f6;

    --radius: 0.5rem;

    --label: #020617;
    --text: #ffffff;

    --sidebar-background: #070d12;
    --sidebar-title-text: #717891;
    --sidebar-text: #b1b7c6;
    --sidebar-active-item: #0b3736;
    --sidebar-hover-item: #082726;
    --placeholder: #afb4bd;

  }
}

@layer base {
  * {
    @apply border-border;
    font-family: "Inter";
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

:focus-visible {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 0px;
}

/* Number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

progress::-webkit-progress-bar {
  background-color: #f1f5f9;
}
progress::-webkit-progress-value {
  background-color: #10b981 !important;
}

.react-datepicker__day--selected {
  background-color: var(--primary) !important;
}